import React, { useEffect, useRef } from 'react';

interface SmartVideoProps {
  src: string;
}

const smartVideoIntersectionObserverOptions = {
  root: null, // Uses viewport
  rootMargin: '0px',
  threshold: 1.0, // Target element fully visible
};

const smartVideoIntersectionObserver = new IntersectionObserver(
  (entries, _) => {
    entries.forEach((entry) => {
      const video = entry.target as HTMLVideoElement;

      if (entry.isIntersecting) {
        video.play();
      } else {
        video.pause();
      }
    });
  },
  smartVideoIntersectionObserverOptions
);

// Video that autoPlays and loops when fully visible
const SmartVideo: React.FC<SmartVideoProps> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef?.current == null) {
      return;
    }

    const videoRefCopy = videoRef.current;
    const timeoutId = window.setTimeout(
      () => smartVideoIntersectionObserver.observe(videoRefCopy),
      100 // Needs some delay, causes errors when run right after page load
    );
    return () => {
      window.clearTimeout(timeoutId);
      smartVideoIntersectionObserver.unobserve(videoRefCopy);
    };
  }, []);

  return <video src={src} ref={videoRef} loop playsInline />;
};

export default SmartVideo;
