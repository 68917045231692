import { createContext } from 'react';
import { PagingParams } from './types';
import Games from './components/pages/games/Games';

const ActivePageContext = createContext<PagingParams>({
  activePage: Games,
  isMobileView: false,
  setActivePage: (_) => (_) => null,
});

export default ActivePageContext;
