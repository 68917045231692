import React, { useContext } from 'react';

import ActivePageContext from '../../ActivePageContext';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './Frame.css';

const Frame: React.FC = () => {
  const { activePage } = useContext(ActivePageContext);

  const pageClass = `page ${activePage.hero ? 'hero' : ''}`;

  return (
    <div className="frame">
      <Header />
      <div className={pageClass}>
        {activePage != null ? (
          <React.Fragment>{<activePage.FC />}</React.Fragment>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default Frame;
