import { Page } from '../../../../types';

import notToScaleCover from '../../../../media/not-to-scale/not-to-scale-cover.jpg';
import { discord } from '../../contact/Contact';
import './NotToScale.css';

const NOT_TO_SCALE_STRING = 'not-to-scale';

const NotToScale: React.FC = () => {
  return (
    <div className={`game-page ${NOT_TO_SCALE_STRING}`}>
      <section>
        <img src={notToScaleCover} alt="Not to Scale Cover" />

        <p>
          Ever play a puzzle where the pieces just don't seem to fit? In{' '}
          <strong>Not to Scale</strong>, <em>this is a feature, not a bug</em>.
          Drawing from product imaging that rarely matches reality, in this
          game, you'll find puzzles where you have all the pieces you need, but
          they don't fit.
        </p>
      </section>

      <section>
        <p>Take a journey through this peaceful and vibrant adventure.</p>
        <p>
          The alpha version of <strong>Not to Scale</strong>, a last minute
          entry to the 2024 GMTK Game Jam, is available on my itch.io page as a
          Windows download.
        </p>

        <a
          href="https://biiirdgames.itch.io/not-to-scale"
          rel="nofollow noreferrer"
          target="_blank"
        >
          <button>
            <h3>Download Not to Scale demo</h3>
          </button>
        </a>
      </section>

      <section>
        <p>
          If you'd like to follow the development of this game, join the biiird
          games Discord server so you don't miss anything!
        </p>

        <a href="/" onClick={discord}>
          <button>
            <h3>Join Discord server</h3>
          </button>
        </a>
      </section>
    </div>
  );
};

const NotToScalePage: Page = {
  FC: NotToScale,
  frameClass: NOT_TO_SCALE_STRING,
  name: 'Not to Scale',
  url: NOT_TO_SCALE_STRING,
};

export default NotToScalePage;
