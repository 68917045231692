import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import './Carousel.css';

interface CarouselProps {
  children: ReactElement[];
}

const DRAGGING_CLASS_NAME = 'dragging';
const NO_POINTER_EVENTS_CLASS_NAME = 'transitioning';
const SCROLL_TRANSITION_CHECK_TIME_MS = 400;

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [displayedMediaIndex, setDisplayedMediaIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartXScrollPos, setDragStartXScrollPos] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [debounceTimerId, setDebounceTimerId] = useState(0);

  const carouselStripRef = useRef<HTMLDivElement>(null);

  const selectMediaIndex = useCallback(
    (index: number, newWidth: number = 0) => {
      setSelectedMediaIndex(index);

      const targetDiv = carouselStripRef.current as HTMLDivElement;

      // If newWidth is specified, that's an onResize call -> instantaneous
      const behavior = newWidth > 0 ? 'auto' : 'smooth';
      const targetWidth = newWidth > 0 ? newWidth : width;

      targetDiv.scrollTo({
        behavior: behavior,
        left: index * targetWidth,
      });
    },
    [width]
  );

  const selectPreviousMediaIndex = () => {
    if (selectedMediaIndex === 0) {
      return;
    }

    selectMediaIndex(selectedMediaIndex - 1);
  };

  const selectNextMediaIndex = () => {
    if (selectedMediaIndex === children.length - 1) {
      return;
    }

    selectMediaIndex(selectedMediaIndex + 1);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (isDragging) {
      return;
    }

    const targetDiv = carouselStripRef.current as HTMLDivElement;

    targetDiv.classList.add(DRAGGING_CLASS_NAME);

    setIsDragging(true);
    setDragStartX(event.clientX);
    setDragStartXScrollPos(targetDiv.scrollLeft + event.clientX);
  };

  const onDrag = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) {
      return;
    }

    const targetDiv = carouselStripRef.current as HTMLDivElement;

    targetDiv.scrollLeft = dragStartXScrollPos - event.clientX;
  };

  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    if (!isDragging) {
      return;
    }

    setIsDragging(false);

    const targetDiv = carouselStripRef.current as HTMLDivElement;

    const dragDir = event.clientX - dragStartX;

    if (dragDir === 0) {
      return;
    } else if (dragDir < 0) {
      selectNextMediaIndex();
    } else {
      selectPreviousMediaIndex();
    }

    targetDiv.classList.add(NO_POINTER_EVENTS_CLASS_NAME);

    setTimeout(() => {
      targetDiv.classList.remove(DRAGGING_CLASS_NAME);
      targetDiv.classList.remove(NO_POINTER_EVENTS_CLASS_NAME);
    }, SCROLL_TRANSITION_CHECK_TIME_MS);
  };

  const onScroll = () => {
    const targetDiv = carouselStripRef.current as HTMLDivElement;

    if (targetDiv == null) return;

    const targetSelectedMediaIndex = Math.round(targetDiv.scrollLeft / width);
    setDisplayedMediaIndex(targetSelectedMediaIndex);

    // Debounce setting the media index to prevent it getting called 200+ times each scroll and save on repaints
    if (!isDragging) {
      window.clearTimeout(debounceTimerId);
      setDebounceTimerId(
        window.setTimeout(() => {
          setSelectedMediaIndex(targetSelectedMediaIndex);
          // Tested multiple timings, 70ms seems like a good value that's still safe but only yields 2 calls maximum
        }, 70)
      );
    }
  };

  const onResize = useCallback(() => {
    if (carouselStripRef.current == null) {
      return;
    }

    const boundingClientRect = carouselStripRef.current.getBoundingClientRect();

    if (
      width === boundingClientRect.width &&
      height === boundingClientRect.height
    ) {
      // No actual resize happened, just a repaint
      return;
    }

    setWidth(boundingClientRect.width);
    setHeight(boundingClientRect.height);

    selectMediaIndex(selectedMediaIndex, boundingClientRect.width);
  }, [width, height, selectMediaIndex, selectedMediaIndex]);

  const initWidthAndHeight = (): boolean => {
    if (carouselStripRef.current == null) {
      return false;
    }

    const boundingClientRect = carouselStripRef.current.getBoundingClientRect();

    if (boundingClientRect.height === 0) {
      return false;
    }

    setWidth(boundingClientRect.width);
    setHeight(boundingClientRect.height);

    return true;
  };

  // Set up resize observer to keep arrow nav height and scroll position correct
  useEffect(() => {
    if (carouselStripRef.current == null) {
      return;
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  // Initial dimension read
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (initWidthAndHeight()) {
        window.clearInterval(intervalId);
      }
    }, 10);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="carousel">
      <div
        className="carousel-strip"
        // draggable
        onMouseDown={onDragStart}
        onMouseMove={onDrag}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        onScroll={onScroll}
        ref={carouselStripRef}
      >
        {children}
      </div>
      <div className="carousel-btn-nav">
        {children.map((child, index) => (
          <div
            className={`carousel-btn-nav-btn ${
              index === displayedMediaIndex ? 'active' : ''
            }`}
            key={child.props.src}
            onClick={() => selectMediaIndex(index)}
          />
        ))}
      </div>
      <div className="carousel-arrow-nav" style={{ height: height }}>
        <div
          className="carousel-arrow-nav-arrow left"
          onClick={selectPreviousMediaIndex}
        >
          &lt;
        </div>
        <div
          className="carousel-arrow-nav-arrow right"
          onClick={selectNextMediaIndex}
        >
          &gt;
        </div>
      </div>
    </div>
  );
};

export default Carousel;
