import { Page } from '../../../types';

import './Contact.css';

const openLinkInNewTab = (url: string) => {
  return (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, '_blank');
  };
};

const discord = openLinkInNewTab('https://discord.gg/d75N3ZPZSp');
const mailto = openLinkInNewTab('mailto:hello@biiirdgames.com');

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <section>
        <h2>Contact</h2>
        <p>
          Join the 💬{' '}
          <a href="/" onClick={discord}>
            biiird games Discord server
          </a>{' '}
          to get updates about game progress, betas and releases and join the
          discussion!
        </p>
        <p>
          Feel free to contact me with your inquiries 📧{' '}
          <a href="/" onClick={mailto}>
            via email.
          </a>
        </p>
      </section>
    </div>
  );
};

const ContactPage: Page = {
  FC: Contact,
  frameClass: 'home',
  name: 'Contact',
  url: 'contact',
};

export default ContactPage;
export { discord };
