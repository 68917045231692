import CatMinerPage from './games/cat-miner/CatMiner';
import NotToScalePage from './games/not-to-scale/NotToScale';
import GamesPage from './games/Games';
import HomePage from './home/Home';
import { Page } from '../../types';
import ContactPage from './contact/Contact';

const allPages = [
  CatMinerPage,
  ContactPage,
  NotToScalePage,
  GamesPage,
  HomePage,
];

const urlToPage = (url: string): Page | null => {
  return allPages.find((page) => page.url === url) ?? null;
};

export { urlToPage };
