import React, { useEffect, useRef, useState } from 'react';

import { Page } from './types';

import ActivePageContext from './ActivePageContext';
import Frame from './components/frame/Frame';
import Home from './components/pages/home/Home';
import './fonts.css';
import './App.css';
import { urlToPage } from './components/pages/pageUtils';

const DEFAULT_PAGE = Home;

const MOBILE_BROWSER_VIEWPORT_WIDTH_THRESHOLD = 800;

const isMobileBrowser = () => {
  return window.innerWidth <= MOBILE_BROWSER_VIEWPORT_WIDTH_THRESHOLD;
};

const App: React.FC = () => {
  const [activePage, setActivePage] = useState<Page>(Home);
  const [isMobileView, setIsMobileView] = useState(isMobileBrowser());

  const appRef = useRef<HTMLDivElement>(null);

  const setActivePageAndUrl = (page: Page) => {
    return (event: React.MouseEvent) => {
      event.preventDefault();

      if (activePage === page) {
        return;
      }

      window.history.pushState(null, '', page.url);

      setActivePage(page);
    };
  };

  const setActivePageByUrl = () => {
    const urlPostfix = window.location.pathname.split('/').join('');

    let page = urlToPage(urlPostfix);

    if (page != null) {
      setActivePage(page);
    } else {
      setActivePage(DEFAULT_PAGE);
    }
  };

  const onResize = () => {
    setIsMobileView(isMobileBrowser());
  };

  useEffect(() => {
    setActivePageByUrl();

    // Navigation hook
    window.addEventListener('popstate', setActivePageByUrl);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('popstate', setActivePageByUrl);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    document.body.className = `background ${activePage.frameClass}`;
    (document.body.parentElement as HTMLElement).scrollTo(0, 0);
  }, [activePage]);

  let appDivClassName = `App${isMobileView ? ' mobile' : ''}`;

  return (
    <ActivePageContext.Provider
      value={{
        activePage: activePage,
        isMobileView: isMobileView,
        setActivePage: setActivePageAndUrl,
      }}
    >
      <div className={appDivClassName} ref={appRef}>
        <Frame />
      </div>
    </ActivePageContext.Provider>
  );
};

export default App;
