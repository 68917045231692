import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Page } from '../../types';

import logo from '../../media/logo-v1.3-horizontal-transparent.png';
import ActivePageContext from '../../ActivePageContext';
import HomePage from '../pages/home/Home';
import CatMinerPage from '../pages/games/cat-miner/CatMiner';
import ContactPage from '../pages/contact/Contact';
import HamburgerBtn from '../ui-elements/hamburger-btn/HamburgerBtn';
import './Header.css';
import NotToScalePage from '../pages/games/not-to-scale/NotToScale';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { activePage, isMobileView, setActivePage } =
    useContext(ActivePageContext);

  const onHamburgerClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const onLinkClick = useCallback(
    (page: Page) => {
      return (event: React.MouseEvent) => {
        setActivePage(page)(event);
        setIsMenuOpen(false);
      };
    },
    [setActivePage]
  );

  const linkPages = [CatMinerPage, NotToScalePage, ContactPage];

  const getPageLinkClassName = (page: Page) =>
    `link ${page.url === activePage.url ? 'active' : ''}`;

  const links = (
    <React.Fragment>
      {linkPages.map((page) => (
        <a
          href={`/${page.url}`}
          className={getPageLinkClassName(page)}
          onClick={onLinkClick(page)}
        >
          {page.name}
        </a>
      ))}
    </React.Fragment>
  );

  const linkCount = links.props.children.length;
  const headerMenuStyle = {
    '--number-of-elements': linkCount,
  } as CSSProperties;

  return (
    <Fragment>
      <div className="header background">
        <div className="logo" onClick={onLinkClick(HomePage)}>
          <img className="double" src={logo} alt="" />
          <img src={logo} alt="biiird games logo" />
        </div>
        {!isMobileView ? <div className="links">{links}</div> : null}
        {isMobileView ? (
          <HamburgerBtn
            className="hamburger-btn"
            isActive={isMenuOpen}
            onClick={onHamburgerClick}
          />
        ) : null}
      </div>
      {isMobileView ? (
        <Fragment>
          <div
            className={`header-menu ${isMenuOpen ? 'open' : ''}`}
            style={headerMenuStyle}
          >
            <div className="links">{links}</div>
            <div className="opaque" onClick={() => setIsMenuOpen(false)} />
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Header;
