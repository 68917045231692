import { useContext } from 'react';

import { Page } from '../../../types';

import hero from '../../../media/home/hero.png';
import ActivePageContext from '../../../ActivePageContext';
import CatMinerPage from '../games/cat-miner/CatMiner';
import NotToScalePage from '../games/not-to-scale/NotToScale';
import './Home.css';

const Home: React.FC = () => {
  const { setActivePage } = useContext(ActivePageContext);

  return (
    <div className="home">
      <div className="hero">
        <img src={hero} alt="biiird games mascot in forest" />

        <p>
          Hi, welcome to the biiird games website! I'm a solo developer working
          my dream job - making games that I, and hopefully You, dear visitor,
          will love.
          <br />
          <br />
          Check out my games below!
        </p>

        <div className="games">
          <a href="/cat-miner" onClick={setActivePage(CatMinerPage)}>
            <button>Cat Miner</button>
          </a>
          <a href="/not-to-scale" onClick={setActivePage(NotToScalePage)}>
            <button>Not to Scale</button>
          </a>
        </div>
      </div>
    </div>
  );
};

const HomePage: Page = {
  FC: Home,
  frameClass: 'home',
  hero: true,
  name: 'Home',
  url: '/',
};

export default HomePage;
