import { Page } from '../../../../types';

import catMinerTitleScreen from '../../../../media/cat-miner/cat-miner-title-screen-transparent.png';
import blastingLoop from '../../../../media/cat-miner/blasting-loop.webm';
import miningLoop from '../../../../media/cat-miner/mining-loop.webm';
import environments from '../../../../media/cat-miner/environments.jpg';
import moonrise from '../../../../media/cat-miner/moonrise.jpg';
import desertTwilight from '../../../../media/cat-miner/desert-twilight.jpg';
import rainbowMountains from '../../../../media/cat-miner/rainbow-mountains.jpg';
import night from '../../../../media/cat-miner/night.jpg';
import rainbowMountainsUi from '../../../../media/cat-miner/rainbow-mountains-ui.jpg';
import minecart from '../../../../media/cat-miner/minecart.jpg';
import teleportation from '../../../../media/cat-miner/teleportation.webm';
import Carousel from '../../../carousel/Carousel';
import SmartVideo from '../../../smart-video/SmartVideo';

import { discord } from '../../contact/Contact';

const CAT_MINER_STRING = 'cat-miner';

const CatMiner: React.FC = () => {
  return (
    <div className={`game-page ${CAT_MINER_STRING}`}>
      <section>
        <img src={catMinerTitleScreen} alt="Game title screen" />
        <p>
          <strong>Cat Miner</strong> is a simulation adventure where you choose
          your style to get through dirt and rock, unearthing increasingly more
          valuable ores and gems and finding new adventures.
        </p>

        <SmartVideo src={miningLoop} />
      </section>

      <section>
        <p>
          Claw your problems away! Or if you're more into explosions, blow 'em
          sky high!
        </p>
        <SmartVideo src={blastingLoop} />
      </section>

      <section>
        <p>
          Discover new horizons as you keep moving through progressively deeper
          and more colorful mining locations.
        </p>

        <Carousel>
          <img
            src={environments}
            alt="Screenshot showcasing multiple game environments"
          />
          <img src={moonrise} alt="Screenshot showcasing game environments" />
          <img
            src={desertTwilight}
            alt="Screenshot showcasing game environments"
          />
          <img
            src={rainbowMountains}
            alt="Screenshot showcasing game environments"
          />
          <img src={night} alt="Screenshot showcasing game environments" />
        </Carousel>
      </section>

      <section>
        <p>Build your own mining operation...</p>

        <img
          src={rainbowMountainsUi}
          alt="Screenshot showcasing game environments"
        />
        <img src={minecart} alt="Screenshot showcasing game environments" />
      </section>

      <section>
        <p>
          ...or just use <s>magic</s> <em>teleporter science</em> to get around!
        </p>
        <SmartVideo src={teleportation} />
      </section>

      <section>
        <p>
          Ready to jump into the action? I am probably working on getting{' '}
          <strong>Cat Miner</strong> available for open beta testing right now!
          Join the biiird games Discord server so you don't miss anything!
        </p>
        <a href="/" onClick={discord}>
          <button>
            <h3>Join Discord server</h3>
          </button>
        </a>{' '}
      </section>
    </div>
  );
};

const CatMinerPage: Page = {
  FC: CatMiner,
  frameClass: CAT_MINER_STRING,
  name: 'Cat Miner',
  url: CAT_MINER_STRING,
};

export default CatMinerPage;
